<template>
  <div>
    <div class="bg-gray-900 p-2 text-white">
      <div class="flex justify-end w-full gap-4 sm:text-sm items-center">
        <UiCountrySelector />
        <span class="h-6 w-px bg-gray-700"></span>
        <UiLocaleSelector /> 
        <span class="h-6 w-px bg-gray-700"></span>
        <UiCurrencySelector />
      </div>
    </div>
  </div>
</template>
