<script lang="ts" setup>
import VProductPrice from './VProductPrice.vue';
import { SfButton, SfDrawer, SfIconClose, useTrapFocus } from '@storefront-ui/vue';

const generalStore = useGeneralStore();
const { isCartSidebarOpen } = storeToRefs(generalStore) as unknown as { isCartSidebarOpen: Ref<boolean> };
const drawerRef = ref();
useTrapFocus(drawerRef, { activeState: isCartSidebarOpen });

const localePath = useLocalePath();
const { data: cart } = useCart();

const link = (product: any) => localePath(`/${product.id_product}-${product.link_rewrite}.html`);
</script>

<template>
  <transition enter-active-class="transition duration-500 ease-in-out"
    leave-active-class="transition duration-500 ease-in-out" enter-from-class="translate-x-full"
    enter-to-class="translate-x-0" leave-from-class="translate-x-0" leave-to-class="translate-x-full">
    <SfDrawer ref="drawerRef" v-model="isCartSidebarOpen" placement="right"
      :class="['bg-neutral-50 z-50', 'border', 'border-gray-300', 'w-full max-w-[370px]']">
      <div>
        <header class="flex items-center justify-between px-10 py-6">
          <p class="flex items-center text-lg">{{ $t('My cart') }}</p>
          <SfButton square variant="tertiary" class="text-gray-900" @click="generalStore.toggleCartSidebar">
            <SfIconClose />
          </SfButton>
        </header>
        <div class="p-4 h-[calc(100vh-88px)] overflow-y-auto">
          <div v-if="cart?.products_count" class="mb-8">
            <div v-for="product in cart.products" :key="product.id_product" class="p-2 bg-white border rounded shadow">
              <div class="flex gap-2">
                <NuxtImg 
                  class="h-full max-w-[180px] w-1/3 aspect-square object-cover" 
                  :src="product.image_url"
                  format="webp"
                  loading="lazy"
                  alt=""
                />
                <div class="flex flex-col justify-center w-full">
                  <NuxtLink :to="link(product)">
                    <h3 class="font-medium text-base md:text-lg line-clamp-2">{{ product.name }}</h3>
                  </NuxtLink>
                  <div class="flex gap-2 items-center">
                    <VProductPrice 
                      :prices-data="{
                        specific_prices: product.specific_prices,
                        price: product.formatted_price,
                        regular_price: product.formatted_regular_price,
                        discount_percentage: product.formatted_discount_percentage
                      }" 
                      size="sm" 
                      container-styles="py-2" 
                    />
                    <div>&times; {{ product.cart_quantity }}</div>
                  </div>
                  {{ product.attributes }}
                </div>
              </div>
            </div>
          </div>
          <div v-else key="empty-cart" class="empty-cart">
            <div class="empty-cart__banner">
              <VEmptyState :title="$t('Your cart is empty')"
                :description="$t('Looks like you haven\'t added any items to the bag yet. Start shopping to fill it in.')" />
            </div>
          </div>
          <div v-if="cart?.products_count">
            <nuxt-link :to="localePath('/cart')">
              <button class="w-full mb-4 btn text-primary-500 font-bold border bg-white"
                @click="generalStore.toggleCartSidebar">
                {{ $t('Go to cart') }}
              </button>
            </nuxt-link>
            <nuxt-link :to="localePath('/checkout')">
              <VButton class="w-full" @click="generalStore.toggleCartSidebar">
                {{ $t('Go to checkout') }}
              </VButton>
            </nuxt-link>
          </div>
          <div v-else>
            <VButton class="w-full" @click="generalStore.toggleCartSidebar">
              {{ $t('Go back shopping') }}
            </VButton>
          </div>
        </div>
      </div>
    </SfDrawer>
  </transition>
</template>
