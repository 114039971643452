<script setup lang="ts">
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useSchemaOrg, defineWebPage, defineWebSite } from '@unhead/schema-org';

const config = useRuntimeConfig();
const { execute: fetchBootstrap } = useBootstrap()
const { execute: fetchCart } = useCart();
const { execute: fetchCustomer } = useAuth();

await Promise.all([fetchBootstrap(), fetchCart(), fetchCustomer()]);

useSchemaOrg([
  defineWebSite({
    name: config.public.app_name,
    url: config.public.app_url,
  }),
  defineWebPage(),
]);
</script>

<template>
  <ClientOnly>
    <CookieOptions />
  </ClientOnly>
  <NuxtLoadingIndicator />
  <Notifications />
  <ClientOnly>
    <GeolocationBar />
    <UiAppBar />
  </ClientOnly>
  <UiAppNavbar />
  <BottomBar />
  <main>
    <slot />
  </main>
  <UiAppFooter />
  <VCartSidebar />
</template>
