<template>
  <header class="relative">
    <!-- Desktop -->
    <div class="bg-gradient-to-b from-gray-50 to-gray-100 shadow">
      <div class="border-b border-gray-200">
        <v-container>
          <div class="grid grid-cols-3 gap-8 h-12 items-center">
            <div class="flex items-center">
              <NuxtLink :to="localePath('/')" aria-label="Homepage" class="flex-shrink-0">
                <UiLogo class="h-[20px] w-auto" :class="{ 'h-[35px]': theme === 'red' }" />
              </NuxtLink>
            </div>
            <div class="flex justify-center w-full gap-4">
              <ul class="hidden gap-4 md:flex md:items-center">
                <li v-for="item in menu">
                  <NuxtLink v-if="!item.children.length" :key="item.label"
                    :to="item.url.replace(config.public.prestashop_url, '')" :aria-label="item.label"
                    class="whitespace-nowrap block text-sm md:text-base text-gray-900 hover:text-gray-700">
                    {{ item.label }}
                  </NuxtLink>
                  <SfDropdown v-else v-model="item.dropdownEvents.isOpen.value" class="menu-dropdown">
                    <template #trigger>
                      <button class="whitespace-nowrap block text-sm md:text-base text-gray-900 hover:text-gray-700"
                        :class="{ 'font-semibold': (item.id === activeMenuItem) }"
                        @click.stop="toggleDropdown(item.id)">
                        {{ item.label }}
                      </button>
                    </template>
                    <div class="rounded bg-gray-100 shadow-lg pb-4 pt-2 px-4 sm:px-6 lg:px-8 w-[100vw]">
                      <NuxtLink :to="item.url.replace(config.public.prestashop_url, '')" :aria-label="item.label"
                        class="block text-lg font-semibold mb-2 text-gray-900 hover:text-gray-700">
                        {{ item.label }}
                      </NuxtLink>
                      <ul class="columns-3">
                        <li class="w-full" v-for="child in item.children" :key="child.id" @click="toggleDropdown(null)">
                          <NuxtLink :key="child.label" :to="child.url.replace(config.public.prestashop_url, '')"
                            :aria-label="child.label"
                            class="whitespace-pre-wrap block text-sm md:text-base text-gray-900 hover:text-gray-700">
                            {{ child.label }}
                          </NuxtLink>
                          <ul class="ml-2 pt-1 pb-2" v-if="child.children.length">
                            <li v-for="subChild in child.children" :key="subChild.id">
                              <NuxtLink :key="subChild.label"
                                :to="subChild.url.replace(config.public.prestashop_url, '')"
                                :aria-label="subChild.label"
                                class="h-6 whitespace-pre-wrap block md:text-sm text-gray-900 hover:text-gray-700">
                                {{ subChild.label }}
                              </NuxtLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </SfDropdown>
                </li>
              </ul>
            </div>
            <div class="flex items-center justify-end">
              <div class="flex items-center gap-2 justify-end text-gray-600 group-hover:text-gray-500">
                <!-- Search -->
                <VSearch />
                <!-- Cart -->
                <div class="">
                  <NuxtLink :to="localePath('/cart')"
                    class="group relative flex items-center justify-center h-10 w-7 sm:h-12 sm:w-12">
                    <svg class="h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                    </svg>
                    <div class="absolute top-3 right-2" v-if="(cart?.products_count ?? 0) > 0">
                      <span class="relative flex h-2 w-2">
                        <span
                          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75"></span>
                        <span class="relative inline-flex rounded-full h-2 w-2 bg-primary-500"></span>
                      </span>
                    </div>
                    <span class="sr-only">{{ $t('items in cart, view bag') }}</span>
                  </NuxtLink>
                </div>
                <!-- Account -->
                <div>
                  <NuxtLink :to="localePath('/my-account')" :title="$t('Account')"
                    class="group flex items-center justify-center h-10 w-7 sm:h-12 sm:w-12">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                  </NuxtLink>
                </div>
              </div>
              <!-- Menu Mobile Button -->
              <div class="flow-root ml-2 lg:ml-0">
                <button type="button" class="md:hidden h-10 w-7 sm:h-12 sm:w-12 flex items-center justify-center"
                  @click="openMobileMenu" title="menu">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </v-container>
      </div>
    </div>
    <!-- Mobile navigation -->
    <div v-if="isOpenMobileMenu" class="md:hidden fixed inset-0 bg-neutral-500 bg-opacity-50 z-40" />
    <transition enter-active-class="transition duration-500 ease-in-out"
      leave-active-class="transition duration-500 ease-in-out" enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0" leave-from-class="translate-x-0" leave-to-class="-translate-x-full">
      <SfDrawer ref="mobileDrawer" v-model="isOpenMobileMenu" placement="left"
        class="md:hidden right-[50px] max-w-[376px] bg-white overflow-y-auto z-50">
        <nav>
          <div class="flex items-center justify-end border-b border-b-neutral-200 border-b-solid">
            <button aria-label="Close menu" class="ml-2 p-3" @click="closMobileMenu">
              <SfIconClose class="text-neutral-500" />
            </button>
          </div>
          <ul class="flex flex-col divide-y">
            <li v-for="category in bootstrap?.menuItems" :key="category.id">
              <div v-if="!category.children.length" @click="closMobileMenu">
                <NuxtLink prefetch
                  class="relative p-3 whitespace-nowrap flex items-center font-medium transition-colors duration-200 ease-out"
                  :to="category.url.replace(config.public.prestashop_url, '')">
                  {{ category.label }}
                </NuxtLink>
              </div>
              <SfAccordionItem v-else :model-value="isOpenAccordion(category.id)"
                @update:model-value="toggleAccordion(category.id, $event)">
                <template #summary>
                  <div class="flex justify-between p-3 font-medium hover:bg-neutral-100 active:bg-blue-100"
                    :class="{ 'bg-gray-200': isOpenAccordion(category.id) }">
                    <p @click="closMobileMenu">
                      <NuxtLink prefetch
                        class="relative whitespace-nowrap flex items-center font-medium transition-colors duration-200 ease-out"
                        :to="category.url.replace(config.public.prestashop_url, '')">
                        {{ category.label }}
                      </NuxtLink>
                    </p>
                    <SfIconChevronLeft :class="[
                      'text-neutral-500',
                      { 'rotate-90': isOpenAccordion(category.id), '-rotate-90': !isOpenAccordion(category.id) }
                    ]" />
                  </div>
                </template>
                <div class="px-3 py-2">
                  <ul class="flex flex-col gap-1">
                    <li v-for="child in category.children" :key="child.id" @click="closMobileMenu">
                      <NuxtLink :key="child.label" :to="child.url.replace(config.public.prestashop_url, '')"
                        :aria-label="child.label"
                        class="whitespace-pre-wrap text-sm text-gray-900 hover:bg-gray-700 h-10 flex items-center">
                        {{ child.label }}
                      </NuxtLink>
                      <ul class="ml-2" v-if="child.children.length">
                        <li v-for="subChild in child.children" :key="subChild.id">
                          <NuxtLink :key="subChild.label" :to="subChild.url.replace(config.public.prestashop_url, '')"
                            :aria-label="subChild.label"
                            class="whitespace-pre-wrap text-sm text-gray-800 hover:bg-gray-700 h-10 flex items-center">
                            {{ subChild.label }}
                          </NuxtLink>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </SfAccordionItem>
            </li>
          </ul>
        </nav>
      </SfDrawer>
    </transition>
  </header>
</template>

<script lang="ts" setup>
import {
  SfAccordionItem,
  SfDrawer,
  SfDropdown,
  SfIconChevronLeft,
  SfIconClose,
  useDisclosure
} from '@storefront-ui/vue';

const config = useRuntimeConfig();
const theme = config.public.app_theme
const localePath = useLocalePath();

const {
  close: closMobileMenu,
  open: openMobileMenu,
  isOpen: isOpenMobileMenu
} = useDisclosure();

const { data: cart } = useCart();
const { data: bootstrap } = useBootstrap();

const activeMenuItem = ref<string | null>(null);

const opened = ref<string | null>(null);

const isOpenAccordion = (id: string) => id === opened.value;

const toggleAccordion = (id: string, open: boolean) => {
  if (open) {
    opened.value = id;
  } else if (isOpenAccordion(id)) {
    opened.value = null;
  }
};

const menu = computed(() => {
  return bootstrap.value?.menuItems.map((item: any) =>
    item = { ...item, dropdownEvents: useDisclosure() }
  ) ?? [];
});

const toggleDropdown = (id: string | null) => {
  activeMenuItem.value = id;
  menu.value.forEach(item => {
    if (id && item.id === id) {
      item.dropdownEvents.open();
    } else {
      item.dropdownEvents.close();
    }
  })
}

onMounted(() => {
  document.addEventListener('click', () => toggleDropdown(null))
})

onUnmounted(() => {
  document.removeEventListener('click', () => toggleDropdown(null));
});
</script>
