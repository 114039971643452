import { useSdk } from '~/sdk';

export const useFooter = () => {
  const nuxtApp = useNuxtApp();
  const {locale} = useI18n();

  const footer = useAsyncData(`footer-${locale.value}`, async () =>
      await useSdk().cms.getContent({
        globalSet: {
          handle: 'footer',
          fragment: `
          ... on footer_GlobalSet {
            copyright
            socials {
              ... on socials_social_BlockType {
                alias
                icon {
                  alt
                  url
                }
                theurl
              }
            }
          }
        `,
        },
      }),
  );

  const menu = useLazyAsyncData(`menu-${locale.value}`, async () => {
    return await useSdk().cms.getContent({
      entries: {
        section: 'menuFooter',
        fragment: `
              title,
              ... on menuFooter_default_Entry {
                id
                title
                slug
                children {
                  id
                  title
                  slug
                  ... on menuFooter_default_Entry {
                    callToAction {
                      text
                      customText
                      title
                      target
                      url
                      element {
                        id
                        slug
                        siteId
                      }
                    }
                  }
                }
              }
            `,
      },
    });
  });

  watch(nuxtApp.$i18n.locale, () => {
    footer.refresh();
    menu.refresh();
  });

  return {
    socials: computed(() => footer.data.value?.socials ?? []),
    menu: computed(() => menu.data.value ?? []),
    copyright: computed(() => footer.data.value?.copyright ?? ''),
  };
};
