<script lang="ts" setup>
import { SfModal, SfIconClose, useDisclosure, SfIconSearch } from '@storefront-ui/vue';

const { isOpen, open, close } = useDisclosure({ initialValue: false });
const { query: searchTerm, data: searchResults } = useSearch();
const localePath = useLocalePath();
const router = useRouter();

const goToSearchPage = () => {
  router.push(localePath('/search'));
  close();
};
</script>

<template>
  <button @click="open" class="h-10 w-7 sm:h-12 sm:w-12 flex items-center justify-center">
    <span class="sr-only">Search</span>
    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
    </svg>
  </button>
  <Teleport to="body">
    <div v-show="isOpen" class="bg-black/[0.50] absolute inset-0 z-20"></div>
    <SfModal v-model="isOpen"
      class="w-full max-w-4xl !top-20 !bottom-auto align-top !p-0 z-40 bg-white rounded-sm overflow-hidden"
      tag="section" role="alertdialog" aria-labelledby="promoModalTitle" aria-describedby="promoModalDesc">
      <div class="max-h-[calc(100vh-10rem)] overflow-y-auto">
        <header class="flex items-center gap-1 bg-gray-100 p-2">
          <SfIconSearch class="text-gray-600" />
          <input @keyup.enter="goToSearchPage" class="form-input" v-model="searchTerm" :placeholder="$t('Search')" />
          <button
            class="text-gray-600 hover:bg-primary-100 hover:text-primary-800 h-9 w-9 flex items-center justify-center border rounded-sm"
            @click="close">
            <SfIconClose />
          </button>
        </header>
        <!-- Empty state, show/hide based on command palette state -->
        <p v-if="searchResults.products?.length == 0" class="p-4 text-sm text-gray-500">
          <VEmptyState :title="$t('No results')" :description="$t('Try with different keywords')" :link="false" />
        </p>

        <template v-if="searchResults.products?.length > 0">
          <ul class="grid grid-cols-1 md:grid-cols-2 py-2 text-sm text-gray-800"
            id="options" role="listbox">
            <li v-for="product in searchResults.products" class="cursor-default select-none px-4 py-2" id="option-1" role="option"
              tabindex="-1" @click="close">
              <div>
                <VProductCard :product="product"></VProductCard>
              </div>
            </li>
          </ul>

          <!-- Load More Button -->
          <div class="py-4 text-center">
            <button role="button"  @click="goToSearchPage" 
            class="btn btn-secondary">
              show more results
            </button>
          </div>
        </template>
      </div>
    </SfModal>
  </Teleport>
</template>
