<script setup lang="ts">
const router = useRouter();
const { locale, locales, setLocaleCookie } = useI18n();
const switchLocalePath = useSwitchLocalePath();

const availableLocales = computed(() => {
  return locales.value;
});

watch(locale, async (val) => {
  setLocaleCookie(val);
  await router.push(switchLocalePath(val));
});
</script>

<template>
  <div>
    <div class="flex">
      <label for="desktop-currency" class="sr-only">Currency</label>
      <div class="group relative -ml-2 rounded-md border-transparent bg-gray-900 focus-within:ring-2 focus-within:ring-white">
          <select
            v-model="locale"
            id="desktop-currency"
            name="currency"
            class="flex focus items-center rounded border-transparent bg-gray-900 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100"
          >
            <option v-for="lang in availableLocales" :value="lang.code" :key="lang.code">
              {{ lang.label }}
            </option>
          </select>
      </div>
    </div>
  </div>
</template>
